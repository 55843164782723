/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'
import Vacancies from 'elements/Vacancy/Vacancies'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'

// Third Party
import styled from 'styled-components'

const StyledBreadcrumb = styled(Breadcrumb)`
  @media screen and (max-width: 450px) {
    .breadcrumb__list {
      flex-wrap: wrap;
      width: 400px;
    }
  }

  @media screen and (max-width: 390px) {
    .breadcrumb__list {
      flex-wrap: wrap;
      width: 320px;
    }
  }

  .breadcrumb__list__item {
    &:last-of-type {
      @media screen and (max-width: 450px) {
        margin-left: 15px;
      }
    }
  }
`

const VacanciesTemplate = ({
  data: {
    page: { title, paginaVacatures, seo },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO seo={seo} />

      <HeroDefault
        fields={{
          image: paginaVacatures.vacaturesbannerimage,
          description: paginaVacatures.vacaturesbannerdescription,
        }}
      />

      <StyledBreadcrumb
        data={title}
        className="py-2"
        pageContext={pageContext}
      />

      <Vacancies />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      title
      uri

      paginaVacatures {
        vacaturesbannerdescription
        vacaturesbannerimage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 2000, placeholder: NONE)
            }
          }
        }
      }

      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default VacanciesTemplate
