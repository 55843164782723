import React from 'react'

// Image
import quote from 'img/icon/quote.svg'
import arrowRight from 'img/icon/arrow_right.svg'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'

const StyledVacancyPreview = styled(motion.div)`
  @media (max-width: 991px) {
    padding: 0 15px !important;
  }
`

const StyledButtonMinimal = styled(ButtonMinimal)`
  a {
    color: ${(props) => props.theme.color.text.secondary};
    font-weight: ${(props) => props.theme.font.weight.l};
    font-size: ${(props) => props.theme.font.size.sm};

    &:hover {
      color: ${(props) => props.theme.color.text.secondary};
    }
  }
`

const StyledArrowRight = styled.img`
  transform: rotate(180deg);
`

const CaseContent = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 40px 200px 40px 80px !important;

  @media screen and (max-width: 991px) {
    padding: 20px !important;
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.lm};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: 26px;
  }
`

const VacancyTitle = styled.div`
  color: ${({ theme }) => theme.color.face.main};
  font-size: 26px;
  font-weight: 800;
`

const VacancyInfo = styled.div`
  color: ${({ theme }) => theme.color.face.contrast};
`

const VacancyPreview = ({ c, className }) => {
  return (
    <StyledVacancyPreview className={`row ${className ? `${className}` : ``}`}>
      <CaseContent className="col-lg-12">
        <VacancyTitle>{c.title}</VacancyTitle>
        <VacancyInfo className="d-flex pb-2">
          <div>{c.postVacature.vacancygeneral.niveau}</div>
          <div className="px-2">-</div>
          <div>{c.postVacature.vacancygeneral.hours}</div>
          <div className="px-2">-</div>
          <div>{c.postVacature.vacancygeneral.function}</div>
        </VacancyInfo>
        <div>{parse(c.postVacature.vacancygeneral.description)}</div>
        <motion.div
          whileHover="hover"
          className="d-flex justify-content-lg-start justify-content-center"
        >
          <StyledButtonMinimal to={c.uri}>
            Bekijk vacature
            <motion.div
              variants={{
                hover: {
                  x: [0, -5, -10, 10, 5, 0],
                  transition: {
                    loop: Infinity,
                    duration: 1,
                  },
                },
              }}
            >
              <StyledArrowRight className="ms-3" src={arrowRight} alt="" />
            </motion.div>
          </StyledButtonMinimal>
        </motion.div>
      </CaseContent>
    </StyledVacancyPreview>
  )
}

export default VacancyPreview
