import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import VacancyPreview from 'elements/Vacancy/VacancyPreview'

// Third Party
import styled from 'styled-components'

const StyledVacancies = styled.div``

const Vacancies = ({ className }) => {
  const {
    vacancies: { nodes: vacancies },
  } = useStaticQuery(graphql`
    {
      vacancies: allWpVacature {
        nodes {
          ...VacaturePreviewFrag
        }
      }
    }
  `)

  return (
    <StyledVacancies className={`container ${className ? `${className}` : ``}`}>
      {vacancies.length > 0 &&
        vacancies.map((c, index) => (
          <VacancyPreview c={c} index={index} className="py-lg-4 py-3" />
        ))}
    </StyledVacancies>
  )
}

export default Vacancies
